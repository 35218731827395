@layer base {
  .grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacer-400);
}

.stepsList {
  margin: 0;
  padding: 0;
}

.stepIcon {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  padding: var(--spacer-200);
}

.stepItem {
  display: flex;
  align-items: center;
  list-style: none;
}

.imageColumn {
  display: none;
}

.buttons {
  display: flex;
  gap: var(--spacer-200);
  flex-wrap: wrap;
  margin-top: var(--spacer-400);
}

.mobileShowcaseImage {
  margin: var(--spacer-200) 0;
}

.showcaseImage {
  width: 100%;
  height: auto;
}

/* large breakpoint */

@container bo-layout-main-container (width >= 1024px) {
  .grid {
    grid-template-columns: 1fr 1fr;
  }

  .imageColumn {
    display: block;
  }

  .subtitle {
    margin-bottom: var(--spacer-400);
  }

  .mobileShowcaseImage {
    display: none;
  }
}

}