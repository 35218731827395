@layer base {
  .layoutWrapper {
  --jt-bo-drawer-width: 240px;
  --jt-bo-drawer-collapsed-width: 55px;
}

.pageContentWrapper {
  display: flex;
}

.mainContent {
  flex: 1;
  container-type: inline-size;
  container-name: bo-layout-main-container;
}

.mainContent__white {
  background-color: var(--color-white);
}

.mainContent__grey {
  background-color: var(--color-grey--025);
}

}