@layer base {
  .data {
  margin-top: var(--spacer-500);
}

.dataList {
  margin: var(--spacer-500) 0 0;
  padding: 0;
  list-style: none;
}

.dataItem {
  display: flex;
  align-items: center;
  font-size: var(--normalBody);
  font-weight: var(--bold);
  line-height: var(--normalBodyLineHeight);
}

.dataItem:not(:first-child) {
  margin-top: var(--spacer-400);
}

.dataImage {
  width: 56px;
  height: 56px;
  margin-right: var(--spacer-300);
  border-radius: var(--radius-200);
}

.form {
  position: relative;
  margin: var(--spacer-400) 0;
  padding: var(--spacer-300);
  border: solid 1px transparent; /* !important */
  border-radius: var(--radius-200);
  background-clip: padding-box; /* !important */
  background-color: var(--color-white);
}

.form::before {
  content: "";
  position: absolute;
  z-index: var(--z-down-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  inset: 0;
  margin: -1px; /* !important */
  border-radius: inherit; /* !important */
  background: linear-gradient(-15deg, rgb(157 44 255 / 100%) 11%, rgb(0 210 0 / 100%) 100%);
}

@media (--large-viewport) {
  .layout {
    position: relative;
  }

  .layout::after {
    content: "";
    position: absolute;
    z-index: var(--z-up-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
    top: 535px;
    left: 47%;
    width: 93px;
    height: 93px;
    background-image: var(--triangleFront);
    background-size: cover;
  }

  .layout::before {
    content: "";
    position: absolute;
    z-index: var(--z-down-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
    top: 580px;
    left: 38%;
    width: 295px;
    height: 123px;
    background-image: var(--triangleBack);
    background-size: cover;
  }

  .form,
  .data {
    margin: var(--spacer-1000) 0;
  }

  .form {
    padding: var(--spacer-500);
  }

  .title {
    font-size: var(--headingBig);
    line-height: var(--headingBigLineHeight);
  }

  .dataItem {
    max-width: 75%;
    font-size: var(--subhead);
    line-height: var(--subheadLineHeight);
  }

  .dataImage {
    width: 72px;
    height: 72px;
  }
}

}