@layer base {
  .main {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacer-300);
  margin-bottom: var(--spacer-500);
}

.navigation {
  display: flex;
  align-items: center;
  gap: var(--spacer-150);
}

.breadcrumb {
  display: none;
}

.titleContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--spacer-50) var(--spacer-100);
}

@media (--medium-viewport) {
  .breadcrumb {
    display: block;
  }

  .navigation {
    gap: var(--spacer-150) var(--spacer-300);
  }
}

}