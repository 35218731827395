@layer base {
  .main {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.main--isEditing {
  flex-direction: column;
  align-items: stretch;
}

.checkboxContainer {
  flex: 0 1 auto;
  min-width: var(--icon-size-medium);
  padding-top: 0;
  padding-left: var(--sk-space-24);
  text-align: center;
}

.slotInfos {
  flex: 1 1 auto;
}

.slotText {
  margin-top: var(--sk-space-4);
}

.buttonContainer {
  flex: 1 1 100%;
  padding-top: var(--sk-space-16);
  text-align: center;
}

.button {
  width: 100%;
  max-width: var(--small-breakpoint); /* avoid having a 670px wide buttons */
}

@media (--medium-viewport) {
  .main--isEditing {
    flex-direction: row;
    align-items: center;
  }

  .slotInfos {
    flex: 1 1 66%;
  }

  .buttonContainer {
    flex: 0 1 34%;
    padding-top: 0;
    padding-left: var(--sk-space-16);
  }
}

}