@layer base {
  .subGroupFieldset {
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 0 var(--spacer-100);
}

.footer {
  margin-top: var(--spacer-300);
}

.uniFooter {
  margin-bottom: var(--spacer-300);
}

.footer p {
  margin: 0;
}

.footer a, .link {
  color: var(--color-black);
}

.button {
  z-index: auto;
}

.conditions {
  margin-top: var(--spacer-300);
  margin-bottom: var(--spacer-300);
}

@media (--major-viewport) {
  .groupFieldset {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 var(--spacer-200);
  }
}

/* DIAL CODE OVERRIDE - BEGIN */
/* Hide the icons to use the maximum of space */
.subGroupFieldset [for="dial_code"] + div :global(.jds-Select__clear-indicator),
.subGroupFieldset [for="dial_code"] + div :global(.jds-Select__option) svg {
  display: none !important;
}

@media (--major-viewport) {
  /* Makes the value smaller */
  .subGroupFieldset [for="dial_code"] + div :global(.jds-Select__single-value) {
    font-size: var(--smallBody) !important;
  }

  /* Reduce space to maximize the display of the value */
  .subGroupFieldset [for="dial_code"] + div :global(.jds-Select__value-container) {
    padding-right: 0 !important;
  }

  .subGroupFieldset [for="dial_code"] + div :global(.jds-Select__indicator) {
    padding-left: 0 !important;
  }
}
/* DIAL CODE OVERRIDE - END */

}