@layer base {
  .subtitle {
  color: var(--color-black);
}

.slotGroup {
  margin-top: var(--sk-space-24);
}

.slotList {
  padding: 0;
  list-style-type: none;
}

}