@layer base {
  .layoutColumn {
  margin-top: 0;
  margin-bottom: 0;
}

.headerLayout {
  background-color: var(--color-white);
}

}